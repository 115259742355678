import React from 'react'
import Seo from '../components/Seo'
import { Breadcrumb } from '../components/Breadcrumb'
import { Footer } from '../components/Footer'
import '../../static/css/404.css'
import '../../static/css/header.css'
import '../../static/css/breadcrumb.css'
import '../../static/css/footer.css'

const NotFound = (): JSX.Element => {
  return (
    <>
      <Seo title={'404 お探しのページは見つかりませんでした'} noIndex={true} />
      <section id="not-found">
        <div className="circles">
          <p>
            404
            <br />
            <small>PAGE NOT FOUND</small>
          </p>
          <span className="circle big" />
          <span className="circle med" />
          <span className="circle small" />
        </div>
        <button className="top__button">
          <a href="/" className="top__buttonA">
            KURORO BLOG トップへ
          </a>
        </button>
      </section>
      <Breadcrumb
        breadcrumb={[
          {
            link: '/',
            text: 'KURORO BLOG',
          },
          {
            link: '/404',
            text: 'ページが見つかりません',
          },
        ]}
      />
      <Footer />
    </>
  )
}

export default NotFound
